import React from 'react'
import { css } from 'styled-components'
import { IoIosSpeedometer, IoIosChatbubbles } from 'react-icons/io'

import Container from '../../components/Container'

import {
  mediaQueries,
  mainOffset,
  colors,
  fontSizes,
  breakpoints,
  space,
  boxShadows,
  palette,
} from '../../utils/tokens'
import { Link } from 'gatsby'
import { MainLayout } from '../../components/MainLayout'

const Select3DPrintingQuote = () => {
  return (
    <MainLayout>
      <main
        css={css({
          padding: `${space[6]}px ${space[4]}px`,
          [mediaQueries.xl]: {
            minHeight: `calc(100vh - ${mainOffset})`,
            maxWidth: breakpoints.xxxl,
            margin: `0 auto`,
            padding: `${space[5]}px ${space[6]}px`,
            paddingTop: `${mainOffset}`
          },
        })}
      >
        <Container
          css={css({
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
          })}
        >
          <h1
            css={css({
              fontSize: fontSizes[4],
              fontWeight: `bold`,
              textAlign: `left`,
              marginBottom: `${space[4]}px`,
              [mediaQueries.xl]: {
                textAlign: `center`,
                marginBottom: `${space[5]}px`,
              },
            })}
          >
            Select which works best for you:
          </h1>
          <div
            css={css({
              display: `grid`,
              gridGap: `${space[4]}px`,
              gridTemplateColumns: `minmax(300px, 450px)`,
              [mediaQueries.xl]: {
                gridTemplateColumns: `repeat(2, minmax(250px, 450px))`,
              },
            })}
          >
            <Link
              to="/instant-quote/3d-printing/"
              css={css({
                ...sectionStyle,
                borderColor: colors.white,
              })}
            >
              <h2
                css={css({
                  ...titleStyle,
                })}
              >
                Instant Quote
              </h2>
              <IoIosSpeedometer
                css={css({
                  ...iconStyle,
                })}
              />
              <p
                css={css({
                  ...subTitleStyle,
                  marginBottom: `${space[4]}px`,
                })}
              >
                For small projects that require minimum assistance.
              </p>
              <hr css={css({ width: `100%` })} />
              <ul
                css={css({
                  ...listStyle,
                })}
              >
                <li
                  css={css({
                    ...proItem,
                  })}
                >
                  See prices instantly
                </li>
                <li
                  css={css({
                    ...proItem,
                  })}
                >
                  Printed on high quality resin material
                </li>
                <li
                  css={css({
                    ...proItem,
                    marginBottom: `${space[3]}px`,
                  })}
                >
                  No signup required
                </li>
                <li
                  css={css({
                    ...consItem,
                  })}
                >
                  Only accepts .STL or .OBJ files
                </li>
              </ul>
            </Link>
            <Link
              to="/custom-quote/3d-printing/category/"
              css={css({
                ...sectionStyle,
                backgroundColor: colors.base,
                borderColor: colors.base,
                color: colors.white,
              })}
            >
              <h2
                css={css({
                  ...titleStyle,
                  color: colors.white,
                })}
              >
                Talk to an expert
              </h2>
              <IoIosChatbubbles
                css={css({
                  ...iconStyle,
                })}
              />
              <p
                css={css({
                  ...subTitleStyle,
                  marginBottom: `${space[4]}px`,
                })}
              >
                For projects that require more than just 3D printing.
              </p>
              <hr
                css={css({
                  width: `100%`,
                })}
              />
              <ul
                css={css({
                  ...listStyle,
                })}
              >
                <li
                  css={css({
                    ...proItem,
                  })}
                >
                  Responds in minutes
                </li>
                <li
                  css={css({
                    ...proItem,
                  })}
                >
                  Different materials to choose from
                </li>
                <li
                  css={css({
                    ...proItem,
                    marginBottom: `${space[3]}px`,
                  })}
                >
                  Get expert advice. No strings attached.
                </li>
              </ul>
            </Link>
          </div>
        </Container>
      </main>
    </MainLayout>
  )
}

const iconStyle = {
  fontSize: `88px`,
  fill: colors.primaryBrand,
  marginBottom: `${space[4]}px`,
}

const titleStyle = {
  fontSize: fontSizes[3],
  fontWeight: `bold`,
  marginBottom: `${space[2]}px`,
  [mediaQueries.xl]: {
    fontSize: fontSizes[3],
    textAlign: `center`,
    marginBottom: `${space[2]}px`,
  },
}

const subTitleStyle = {
  fontSize: fontSizes[3],
  [mediaQueries.xl]: {
    textAlign: `center`,
    maxWidth: `300px`,
    marginLeft: `auto`,
    marginRight: `auto`,
  },
}

const sectionStyle = {
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  height: `100%`,
  textDecoration: `none`,
  padding: `${space[3]}px ${space[4]}px`,
  borderRadius: `${space[2]}px`,
  cursor: `pointer`,
  boxShadow: boxShadows.card,
  transition: `border 300ms ease-in-out`,
  border: `4px solid`,
  ':hover, :focus': {
    border: `4px solid ${colors.primaryBrand}`,
  },
  [mediaQueries.lg]: {
    padding: `${space[4]}px ${space[4]}px`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
  },
}

const listStyle = {
  listStyle: `none`,
  marginLeft: 0,
  fontSize: fontSizes[2],
  maxWidth: `300px`,
}

const proItem = {
  display: `flex`,
  marginLeft: `-0.7rem`,
  fontWeight: `500`,
  marginBottom: `${space[2]}px`,
  '::before': {
    content: `"+"`,
    marginRight: `0.7rem`,
    position: `relative`,
    left: 0,
    color: palette.green[50],
  },
}

const consItem = {
  display: `flex`,
  marginLeft: `-0.7rem`,
  '::before': {
    content: `"-"`,
    marginRight: `0.7rem`,
    position: `relative`,
    left: 0,
    color: palette.red[50],
  },
}


export default Select3DPrintingQuote
